import React from 'react';
import { Router } from 'wouter';
import RouterComponent from './router';
import QueryProvider from './provider/query-provider';

function App() {
    return (
        <QueryProvider>
            <Router>
                <RouterComponent />
            </Router>
        </QueryProvider>
    );
}

export default App;
