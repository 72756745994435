import React from 'react';
import { useQuery } from '@tanstack/react-query';
import toHref, { getSite } from '../tools/fetcher';
import { classNames } from '../tools/helpers';
import TestimonialSlider from './TestimonialSlider';

export default function Testimonial({
    className,
    classNameSlider,
    innerWidth,
}) {
    const { data } = useQuery({
        queryKey: ['sites'],
        queryFn: getSite,
    });

    const sectionTitle = data?.testimonial?.section_title || `Testimoni.`;

    const title =
        data?.testimonial?.title ||
        `Kisah kesuksesan mereka belajar bersama kami.`;

    const description =
        data?.testimonial?.description ||
        `Tujuannya adalah untuk menjaga pertumbuhan terbaik nilai Anda, 
        yang memberikan Anda peluang lebih besar untuk diterima di perguruan 
        tinggi favorit Anda melalui pengajuan program undangan universitas.`;

    const pathImg = (imgName) =>
        toHref(
            `/wp-content/plugins/react-plugin/react-app/build/assets/decorative/${imgName}.svg`,
        );

    return (
        <div
            className={classNames(
                'flex flex-col gap-y-8 items-center justify-between md:flex-row',
                className,
            )}
        >
            <div className={'max-w-[560px] space-y-4 z-10'}>
                <div
                    className={
                        'font-bold text-sm text-primary-black-100 tracking-[0.2em] uppercase'
                    }
                >
                    {sectionTitle}
                </div>
                <div className={'font-bold leading-[52px] text-[40px]'}>
                    {title}
                </div>
                <div className={'text-base text-primary-black-50'}>
                    {description}
                </div>
            </div>
            <div className={classNameSlider}>
                <div className="absolute hidden -top-[55px] right-0 md:block z-10">
                    <img
                        src={pathImg('testimonial-2')}
                        alt="Benefit Decorative"
                    />
                </div>
                <div className={innerWidth}>
                    <TestimonialSlider />
                </div>
            </div>
        </div>
    );
}
