import React from 'react';
import { classNames } from '../tools/helpers';
import toHref from '../tools/fetcher';

export default function Logo({ children, className }) {
    const pathImg = (imgName) =>
        toHref(
            `/wp-content/plugins/react-plugin/react-app/build/assets/${imgName}.svg`,
        );

    return (
        <div className="flex gap-x-3">
            <img
                src={pathImg('logo32')}
                alt="KelasInternasional.id Logo"
                width={32}
            />
            <h1
                className={classNames(
                    '!m-0 font-bold leading-8 text-[24px]',
                    className,
                )}
            >
                Kelas
                <span className={'font-normal'}>Internasional.id</span>
                {children}
            </h1>
        </div>
    );
}
