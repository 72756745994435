import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { classNames } from '../tools/helpers';
import { getFeature, getSite } from '../tools/fetcher';

export default function Feature({ className }) {
    const [features, setFeatures] = useState([]);
    const { data } = useQuery({
        queryKey: ['sites'],
        queryFn: getSite,
    });

    useEffect(() => {
        getFeature().then((result) => {
            if (!result?.code) {
                const dt = result.map((d) => ({
                    title: d?.title?.rendered,
                    description: d?.feature_details?.description,
                }));
                setFeatures(dt);
            }
        });
    }, []);

    return (
        <div
            className={classNames(
                'flex flex-col gap-8 justify-between md:flex-row md:gap-[64px]',
                className,
            )}
        >
            <div className={'space-y-4 z-10 md:max-w-[560px]'}>
                <p
                    className={
                        'font-bold text-sm text-primary-blue-101 tracking-[0.2em]'
                    }
                >
                    {data?.feature?.section_title}
                </p>
                <p
                    className={
                        'font-bold leading-[32px] text-[24px] text-primary-black-100 sm:leading-[52px] sm:text-[40px]'
                    }
                >
                    {data?.feature?.title}
                </p>
                <p className={'leading-7 text-primary-black-50'}>
                    {data?.feature?.description}
                </p>
                {data?.feature?.image_src && (
                    <div className={'pt-6 md:px-5'}>
                        <img
                            src={data?.feature?.image_src}
                            alt={data?.feature?.image_alt}
                        />
                    </div>
                )}
            </div>
            <div className={'sm:max-w-[500px]'}>
                <ul className={'divide-y divide-primary-white-40 space-y-8'}>
                    {features.map((feature, index) => (
                        <div key={index} className={'pt-8 sm:px-[30px]'}>
                            <div className={'flex items-center gap-6'}>
                                <img
                                    src={data?.feature_list_icon?.image_src}
                                    alt={data?.feature_list_icon?.image_alt}
                                    className={'h-10 w-10'}
                                />
                                <p
                                    className={
                                        'font-bold leading-8 text-xl text-primary-black-100'
                                    }
                                >
                                    {feature.title.replace(/&#038;/g, '&')}
                                </p>
                            </div>
                            <div
                                className={
                                    'leading-7 ml-[64px] text-primary-black-50'
                                }
                            >
                                {feature.description}
                            </div>
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    );
}
