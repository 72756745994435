import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { classNames } from '../tools/helpers';
import { getService, getSite } from '../tools/fetcher';
import Button from './Button';

export default function Service({ className }) {
    const [services, setServices] = useState([]);
    const { data } = useQuery({
        queryKey: ['sites'],
        queryFn: getSite,
    });

    useEffect(() => {
        getService().then((result) => {
            if (!result?.code) {
                const dt = result.map((d) => ({
                    title: d?.title?.rendered,
                    description: d?.service_details?.description,
                    imageSrc: d?.service_details?.image_src,
                    imageAlt: d?.service_details?.image_alt,
                }));
                setServices(dt);
            }
        });
    }, []);

    return (
        <div className={classNames('space-y-8 sm:space-y-[60px]', className)}>
            <div className={'flex flex-col gap-4 items-center'}>
                <p
                    className={
                        'font-bold text-sm text-primary-blue-101 text-center tracking-[0.2em]'
                    }
                >
                    {data?.benefit?.section_title}
                </p>
                <p
                    className={
                        'font-bold leading-[32px] max-w-[760px] text-center text-[24px] text-primary-black-100 sm:leading-[52px] sm:text-[40px]'
                    }
                >
                    {data?.benefit?.title}
                </p>
                <p className={'leading-7 text-primary-black-50'}>
                    {data?.benefit?.description}
                </p>
            </div>
            <div
                className={
                    'flex flex-wrap gap-x-10 gap-y-6 justify-center w-full sm:gap-y-[60px]'
                }
            >
                {services.map((service, index) => (
                    <div
                        key={index}
                        className={
                            'bg-white space-y-2 p-8 rounded-2xl w-full sm:w-[360px] z-10'
                        }
                    >
                        <img
                            src={service.imageSrc}
                            alt={service.imageAlt}
                            className={'h-10 w-10 sm:h-[60px] sm:w-[60px]'}
                        />
                        <p
                            className={
                                'font-bold leading-7 text-xl text-primary-black-100 mt-2'
                            }
                        >
                            {service.title.replace(/&#038;/g, '&')}
                        </p>
                        <div className={'leading-6 text-primary-black-50'}>
                            {service.description}
                        </div>
                    </div>
                ))}
                <div
                    className={
                        'content-center space-y-6 p-8 w-full sm:w-[360px] z-10'
                    }
                >
                    <div
                        className={
                            'font-bold leading-7 text-xl text-primary-black-100'
                        }
                    >
                        {data?.service_solution?.title}
                    </div>
                    <div className={'flex items-center'}>
                        <Button
                            type="a"
                            href={data?.navbar_button?.link_url}
                            className={
                                'bg-primary-blue-100 !rounded-[30px] sm:px-8 !sm:h-[60px]'
                            }
                        >
                            {data?.navbar_button?.description}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
