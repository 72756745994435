/* eslint-disable */
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import Layout from './Layout';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { getSite } from '../tools/fetcher';
import Loading from '../components/Loading';
import BlogHorizontal from '../components/BlogHorizontal';

export default function BlogPageHorizontal() {
    const { isPending, isError, data } = useQuery({
        queryKey: ['sites'],
        queryFn: getSite,
    });

    useEffect(() => {
        let x = 1;
        const interval = setInterval(() => {
            const params = new URLSearchParams(window.location.search);
            const section = params.get('section');
            const element = document.getElementById(section);
            if (section) {
                console.log('CLEAR');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    clearInterval(interval);
                }
            }
            x += 1;
            if (x > 50) {
                // 10 seconds
                clearInterval(interval);
            }
        }, 200);
        return () => clearInterval(interval);
    }, []); // Empty dependency array ensures this runs only once on mount

    if (isPending) {
        return <Loading />;
    }
    if (isError) {
        return <Loading>Error loading data</Loading>;
    }

    return (
        <Layout className="min-w-[360px] font-nunito text-primary-black-100">
            <div className="px-4 sm:px-6 lg:px-8 xl:px-[140px] sm:pt-6">
                <Navbar data={data} className={'max-w-[1160px] mx-auto'} />
            </div>
            <div
                id="Blog"
                className="px-4 py-[60px] sm:px-6 lg:px-8 xl:px-[140px] sm:pt-[80px] sm:py-[120px] bg-white"
            >
                <BlogHorizontal className={'max-w-[1160px] mx-auto'} />
            </div>
            <div
                id="footer"
                className="bg-primary-black-100 pt-10 px-4 relative sm:pt-20 sm:px-6 lg:px-8 xl:px-[140px]"
            >
                <Footer data={data} className={'max-w-[1160px] mx-auto'} />
            </div>
        </Layout>
    );
}
