export default function toHref(pathname, params) {
    let newPathname = pathname;

    const q = [];

    if (params?.query) {
        const keys = Object.keys(params?.query);
        if (keys.length) {
            if (keys.includes('page')) {
                q.push(`page=${params?.query.page}`);
            }
            if (keys.includes('perPage')) {
                q.push(`per_page=${params?.query.perPage}`);
            }
        }
    }

    if (q.length) {
        newPathname = `${newPathname}?${q.join('&')}`;
    }

    return new URL(newPathname, process.env.REACT_APP_API_URL).href;
}

export async function getBenefit(params) {
    const response = await fetch(toHref('/wp-json/wp/v2/benefit', params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.json();
}

export async function getFeature(params) {
    const response = await fetch(toHref('/wp-json/wp/v2/feature', params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.json();
}

export async function getPartner(params) {
    const response = await fetch(toHref('/wp-json/wp/v2/partner', params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.json();
}

export async function getService(params) {
    const response = await fetch(toHref('/wp-json/wp/v2/service', params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.json();
}

export async function getTestimonial(params) {
    const response = await fetch(toHref('/wp-json/wp/v2/testimonial', params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.json();
}

export async function getSite(params) {
    const response = await fetch(toHref('/wp-json/api/sites', params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const data = await response.json();

    return data.reduce(
        (acc, cur) => Object.assign(acc, { [cur.code]: cur }),
        {},
    );
}

export async function getFeatureMedia(featureMediaId) {
    const response = await fetch(
        toHref(`wp-json/wp/v2/media/${featureMediaId}`),
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    return response.json();
}

export async function getPost(page = 1, postsPerPage = 10, json = true) {
    const response = await fetch(
        toHref(
            `/wp-json/wp/v2/posts?status=publish&page=${page}&per_page=${postsPerPage}`,
        ),
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (!json) {
        return response;
    }
    return response.json();
}

export async function getAuthor(authorId) {
    const response = await fetch(toHref(`wp-json/wp/v2/users/${authorId}`), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.json();
}
