import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import Navbar from '../components/Navbar';
import Layout from './Layout';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import toHref, { getSite } from '../tools/fetcher';
import Partner from '../components/Partner';
import Feature from '../components/Feature';
import Benefit from '../components/Benefit';
import Service from '../components/Service';
import Testimonial from '../components/Testimonial';

export default function Homepage() {
    const { isPending, isError, data } = useQuery({
        queryKey: ['sites'],
        queryFn: getSite,
    });

    useEffect(() => {
        let x = 1;
        const interval = setInterval(() => {
            const params = new URLSearchParams(window.location.search);
            const section = params.get('section');
            const element = document.getElementById(section);
            if (section) {
                console.log('CLEAR');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    clearInterval(interval);
                }
            }
            x += 1;
            if (x > 50) {
                // 10 seconds
                clearInterval(interval);
            }
        }, 200);
        return () => clearInterval(interval);
    }, []); // Empty dependency array ensures this runs only once on mount

    if (isPending) {
        return <Loading />;
    }
    if (isError) {
        return <Loading>Error loading data</Loading>;
    }

    const pathImg = (imgName) =>
        toHref(
            `/wp-content/plugins/react-plugin/react-app/build/assets/decorative/${imgName}.svg`,
        );

    return (
        <Layout className="bg-white min-w-[360px] font-nunito text-primary-black-100">
            <div className="px-4 sm:px-6 lg:px-8 xl:px-[140px] sm:py-6">
                <Navbar data={data} className={'max-w-[1160px] mx-auto'} />
            </div>
            <div
                id="hero"
                className="px-4 pt-[60px] sm:px-6 lg:px-8 xl:px-[140px] sm:pt-[80px]"
            >
                <div className="absolute hidden left-0 top-[96px] md:block md:opacity-50 lg:opacity-100">
                    <img src={pathImg('hero')} alt="Hero Decorative" />
                </div>
                <Hero className={'max-w-[1160px] mx-auto mb-[73px]'} />
                <Partner className={'max-w-[1160px] mx-auto'} />
            </div>
            <div
                id="feature"
                className="pt-10 px-4 sm:pt-20 sm:px-6 lg:px-8 xl:px-[140px]"
            >
                <Feature
                    data={data}
                    className={'max-w-[1160px] mx-auto mb-[73px]'}
                />
            </div>
            <div
                id="benefit"
                className="pt-10 px-4 relative sm:pt-20 sm:px-6 lg:px-8 xl:px-[140px]"
            >
                <div className="absolute hidden bottom-0 right-0 md:block md:opacity-50 lg:opacity-100">
                    <img src={pathImg('benefit')} alt="Benefit Decorative" />
                </div>
                <Benefit
                    data={data}
                    className={'max-w-[1160px] mx-auto pb-[70px]'}
                />
            </div>
            <div
                id="service"
                className="bg-primary-white-45 pt-10 px-4 relative sm:pt-20 sm:px-6 lg:px-8 xl:px-[140px] z-10"
            >
                <Service
                    data={data}
                    className={'max-w-[1160px] mx-auto pb-[70px]'}
                />
            </div>
            <div
                id="testimonial"
                className="px-4 py-[120px] relative sm:px-6 lg:px-8 xl:px-[140px]"
            >
                <div className="absolute hidden -top-[342px] left-0 md:block z-0">
                    <img
                        src={pathImg('testimonial-1')}
                        alt="Benefit Decorative"
                    />
                </div>
                <div className="absolute hidden bottom-[67px] left-[52px] md:block z-0">
                    <img
                        src={pathImg('testimonial-3')}
                        alt="Benefit Decorative"
                    />
                </div>
                <div className="absolute hidden -bottom-[161px] right-0 md:block z-0">
                    <img
                        src={pathImg('testimonial-4')}
                        alt="Benefit Decorative"
                    />
                </div>
                <Testimonial
                    className={'max-w-[1160px] mx-auto pb-[70px]'}
                    classNameSlider={'relative'}
                    innerWidth={'mx-auto w-[calc(100vw-8px)] sm:max-w-[500px]'}
                />
            </div>
            <div
                id="footer"
                className="bg-primary-black-100 pt-10 px-4 relative sm:pt-20 sm:px-6 lg:px-8 xl:px-[140px]"
            >
                <Footer data={data} className={'max-w-[1160px] mx-auto'} />
            </div>
        </Layout>
    );
}
