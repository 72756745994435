/* eslint-disable indent */
import React, { useMemo } from 'react';
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Transition,
} from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from '../tools/helpers';
import Button from './Button';
import Logo from './Logo';

export default function Navbar({ data, className, showMenu = true }) {
    const splitDescription = useMemo(
        () => data?.navbar_menu?.description?.split('|') || [],
        [data],
    );

    const links = showMenu
        ? splitDescription
              .map((str) => {
                  const split = str.split('@');
                  if (split.length >= 2) {
                      return {
                          label: split[0],
                          href: split[1],
                      };
                  }
                  return false;
              })
              .filter(Boolean)
        : [];

    return (
        <Disclosure as="nav" className={classNames(className)}>
            {({ open }) => (
                <>
                    <div className="mx-auto relative">
                        <div className="flex h-16 sm:h-12 justify-between">
                            <div className="flex flex-1">
                                <div className="flex flex-shrink-0 items-center">
                                    <a href={'/'} className={'!no-underline'}>
                                        <Logo />
                                        <span className="sr-only">
                                            KelasInternasional.id
                                        </span>
                                    </a>
                                </div>
                                <div className="hidden text-sm sm:space-x-2 sm:ml-6 sm:flex flex-1 justify-center items-center md:space-x-8">
                                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                    {links?.map((link, index) => (
                                        <a
                                            href={link.href}
                                            key={index}
                                            className="!no-underline inline-flex items-center justify-center h-7 text-base font-normal text-black"
                                        >
                                            {link.label}
                                        </a>
                                        // className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                    ))}
                                </div>
                            </div>
                            <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                <Button
                                    type="a"
                                    href={data?.navbar_button?.link_url}
                                    className="bg-white border border-primary-white-40 !rounded-3xl !text-primary-blue-100"
                                    target="_blank"
                                >
                                    {data?.navbar_button?.description}
                                </Button>
                            </div>
                            <div className="flex items-center sm:hidden absolute z-30 top-0 right-0 pt-4">
                                {/* Mobile menu button */}
                                <DisclosureButton className="relative inline-flex items-center justify-center rounded-md text-gray-400 hover:bg-none hover:text-gray-500 focus:outline-none focus:ring-0">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </DisclosureButton>
                            </div>
                        </div>
                    </div>

                    <Transition
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 -translate-y-6"
                        enterTo="opacity-100 translate-y-0"
                        leave="duration-300 ease-out"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-6"
                    >
                        <DisclosurePanel className="sm:hidden absolute inset-x-0 top-0 z-20">
                            <div className="backdrop-blur-sm bg-white/[.8] p-4 pt-[64px] pb-10 space-y-4">
                                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                                {links.map((link, index) => (
                                    <DisclosureButton
                                        key={index}
                                        as="a"
                                        href={link.href}
                                        className="!no-underline font-nunito min-h-8 flex items-center text-[24px] leading-[32px] font-normal text-black"
                                    >
                                        {link.label}
                                    </DisclosureButton>
                                ))}
                                <Button
                                    className={
                                        'w-fit mt-10 text-base leading-[24px] bg-white !border !border-primary-white-40 !rounded-3xl !text-primary-blue-100'
                                    }
                                    style={{
                                        border: '1px',
                                        borderColor: '#DEE1EB',
                                    }}
                                    type="a"
                                    href={data?.navbar_button?.link_url}
                                    target="_blank"
                                >
                                    {data?.navbar_button?.description}
                                </Button>
                            </div>
                        </DisclosurePanel>
                    </Transition>
                </>
            )}
        </Disclosure>
    );
}
