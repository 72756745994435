import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { classNames } from '../tools/helpers';
import { getBenefit, getSite } from '../tools/fetcher';

export default function Benefit({ className }) {
    const [benefits, setBenefits] = useState([]);
    const { data } = useQuery({
        queryKey: ['sites'],
        queryFn: getSite,
    });

    useEffect(() => {
        getBenefit().then((result) => {
            if (!result?.code) {
                const dt = result.map((d) => ({
                    title: d?.title?.rendered,
                    description: d?.benefit_details?.description,
                }));
                setBenefits(dt);
            }
        });
    }, []);

    return (
        <div className={classNames('space-y-[60px]', className)}>
            <div className={'flex flex-col gap-4 items-center'}>
                <p
                    className={
                        'font-bold text-sm text-primary-blue-101 text-center tracking-[0.2em]'
                    }
                >
                    {data?.benefit?.section_title}
                </p>
                <p
                    className={
                        'font-bold leading-[32px] max-w-[760px] text-center text-[24px] text-primary-black-100 sm:leading-[52px] sm:text-[40px]'
                    }
                >
                    {data?.benefit?.title}
                </p>
                <p className={'leading-7 text-primary-black-50'}>
                    {data?.benefit?.description}
                </p>
            </div>
            <div
                className={
                    'flex flex-wrap gap-x-10 gap-y-[60px] justify-center w-full'
                }
            >
                {benefits.map((benefit, index) => (
                    <div key={index} className={'w-full sm:w-[360px] z-10'}>
                        <div className={'flex gap-6'}>
                            <img
                                src={data?.benefit_list_icon?.image_src}
                                alt={data?.benefit_list_icon?.image_alt}
                                className={'h-10 w-10'}
                            />
                            <p
                                className={
                                    'font-bold leading-7 text-xl text-primary-black-100'
                                }
                            >
                                {benefit.title.replace(/&#038;/g, '&')}
                            </p>
                        </div>
                        <div
                            className={
                                'leading-6 ml-[64px] text-primary-black-50'
                            }
                        >
                            {benefit.description}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
