import Logo from './Logo';
import { classNames } from '../tools/helpers';

export default function Loading({ children, className }) {
    return (
        <div
            className={classNames(
                'absolute inset-0 flex flex-col items-center justify-center',
                className,
            )}
        >
            <Logo className={'animate-pulse'}>...</Logo>
            {children}
        </div>
    );
}
