import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { classNames } from '../tools/helpers';
import { getSite } from '../tools/fetcher';
import Button from './Button';

export default function Hero({ className }) {
    const { data } = useQuery({
        queryKey: ['sites'],
        queryFn: getSite,
    });

    return (
        <div
            className={classNames(
                'flex gap-[64px] items-center justify-between',
                className,
            )}
        >
            <div className={'space-y-4 z-10 sm:max-w-[560px] sm:space-y-6'}>
                <p
                    className={
                        'font-bold leading-[32px] text-[24px] text-primary-black-100 sm:leading-[56px] sm:text-[48px]'
                    }
                >
                    {data?.hero?.title}
                </p>
                <p className={'leading-7 max-w-[460px] text-primary-black-50'}>
                    {data?.hero?.description}
                </p>
                <div className={'flex items-center'}>
                    <Button
                        type="a"
                        href={data?.hero_button?.link_url}
                        className={
                            'bg-primary-blue-100 !rounded-[30px] sm:px-8 !sm:h-[60px]'
                        }
                        target="_blank"
                    >
                        {data?.hero_button?.description}
                    </Button>
                </div>
            </div>
            {data?.hero?.image_src && (
                <div
                    className={
                        'absolute hidden right-0 top-[128px] md:block md:opacity-50 md:w-[500px] lg:opacity-100 lg:w-max'
                    }
                >
                    <img
                        className={''}
                        src={data?.hero?.image_src}
                        alt={data?.hero?.image_alt}
                    />
                </div>
            )}
        </div>
    );
}
