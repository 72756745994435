import React, { useEffect, useState } from 'react';
import { getPartner } from '../tools/fetcher';
import { classNames } from '../tools/helpers';

export default function Partner({ className }) {
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        getPartner().then((result) => {
            if (!result?.code) {
                const dt = result.map((d) => ({
                    title: d?.title?.rendered,
                    imageSrc: d?.partner_details?.image_src,
                    imageAlt: d?.partner_details?.image_alt,
                }));
                setPartners(dt);
            }
        });
    }, []);

    return (
        <div className={classNames('sm:py-[74px]', className)}>
            <div
                className={
                    'partner grid grid-cols-2 gap-y-[36px] justify-items-center md:grid-cols-4 lg:grid-cols-6 sm:gap-x-[72px]'
                }
            >
                {partners.length > 0 &&
                    partners.map((partner, index) => (
                        <img
                            key={index}
                            src={partner.imageSrc}
                            alt={partner.title}
                            className={'max-h-[72px] sm:max-h-[120px] my-auto'}
                        />
                    ))}
            </div>
        </div>
    );
}
