import React, { useMemo } from 'react';
import { classNames } from '../tools/helpers';
import Logo from './Logo';
import Button from './Button';

export default function Footer({ data, className }) {
    const splitDescription = useMemo(
        () => data?.footer_copyright?.description?.split('|') || [],
        [data],
    );

    const splitAdditionalServices = useMemo(
        () => data?.additional_services?.description?.split('|') || [],
        [data],
    );

    const links = splitAdditionalServices
        .map((str) => {
            const split = str.split('@');
            if (split.length >= 2) {
                return {
                    title: split[0],
                    href: split[1],
                };
            }
            return false;
        })
        .filter(Boolean);

    const contacts = [
        {
            icon: 'map',
            linkUrl: data?.footer_map?.link_url,
            imageSrc: data?.footer_map?.image_src,
            imageAlt: data?.footer_map?.image_alt,
            description: data?.footer_map?.description,
        },
        {
            icon: 'email',
            linkUrl: data?.footer_email?.link_url,
            imageSrc: data?.footer_email?.image_src,
            imageAlt: data?.footer_email?.image_alt,
            description: data?.footer_email?.description,
        },
    ];

    const socials = [
        {
            icon: 'twitter',
            linkUrl: data?.footer_twitter?.link_url,
            imageSrc: data?.footer_twitter?.image_src,
            imageAlt: data?.footer_twitter?.image_alt,
            description: data?.footer_twitter?.description,
        },
        {
            icon: 'facebook',
            linkUrl: data?.footer_facebook?.link_url,
            imageSrc: data?.footer_facebook?.image_src,
            imageAlt: data?.footer_facebook?.image_alt,
            description: data?.footer_facebook?.description,
        },
        {
            icon: 'linkedin',
            linkUrl: data?.footer_linkedin?.link_url,
            imageSrc: data?.footer_linkedin?.image_src,
            imageAlt: data?.footer_linkedin?.image_alt,
            description: data?.footer_linkedin?.description,
        },
        {
            icon: 'instagram',
            linkUrl: data?.footer_instagram?.link_url,
            imageSrc: data?.footer_instagram?.image_src,
            imageAlt: data?.footer_instagram?.image_alt,
            description: data?.footer_instagram?.description,
        },
    ];
    return (
        <div className={classNames('', className)}>
            <div
                className={
                    'flex flex-col gap-8 justify-between mb-10 md:flex-row sm:mb-20'
                }
            >
                <div className={'max-w-[500px] space-y-4 sm:space-y-8'}>
                    <Logo className={'text-white'} />
                    <p
                        className={
                            'font-bold leading-[32px] m-0 text-3xl text-white sm:leading-[56px] sm:text-4xl'
                        }
                    >
                        {data?.footer?.description ||
                            `Wujudkan Impian Kuliah di Perguruan Tinggi Favorit`}
                    </p>
                    <div className={'flex items-center'}>
                        <Button
                            type="a"
                            href={data?.hero_button?.link_url}
                            className={
                                'bg-primary-blue-100 !rounded-[30px] sm:px-8 !sm:h-[60px]'
                            }
                            target="_blank"
                        >
                            {data?.hero_button?.description}
                        </Button>
                    </div>
                </div>
                <div className={'space-y-4 sm:space-y-6'}>
                    <p
                        className={
                            'font-bold text-white text-xl'
                        }
                    >
                        {data?.additional_services?.title}
                    </p>
                    <ul
                        className={
                            'leading-[28px] m-0 p-0 space-y-2 sm:space-y-4'
                        }
                    >
                        {links.map((link, index) => (
                            <li
                                key={index}
                                className={
                                    'flex items-center gap-4 sm:gap-6'
                                }
                            >
                                <a
                                    href={link.href}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={'!no-underline'}
                                >
                                    <span
                                        className={
                                            'text-primary-white-50 text-nowrap'
                                        }
                                    >
                                        {link.title}
                                    </span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={'space-y-8 sm:space-y-12'}>
                    <ul
                        className={
                            'leading-[28px] p-0 space-y-4 text-primary-white-50 sm:space-y-8'
                        }
                    >
                        {contacts.map((contact, index) => (
                            <li
                                key={index}
                                className={'flex items-start gap-4 sm:gap-6'}
                            >
                                <a href={contact.linkUrl}>
                                    <div
                                        className={
                                            'bg-primary-black-70 flex h-10 items-center justify-center rounded-full w-10'
                                        }
                                    >
                                        <img
                                            src={contact.imageSrc}
                                            alt={contact.imageAlt}
                                        />
                                    </div>
                                </a>
                                <span
                                    className={
                                        'mt-1.5 text-justify sm:w-[266px]'
                                    }
                                >
                                    {contact.description}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div
                className={
                    'border-t border-primary-black-50 flex flex-col gap-4 items-start justify-between py-8 sm:flex-row sm:items-center'
                }
            >
                <p className={'m-0 text-primary-white-50'}>
                    {splitDescription.length >= 1 && splitDescription[0]}{' '}
                    {new Date().getFullYear()}{' '}
                    {splitDescription.length >= 2 && splitDescription[1]}
                </p>
                <ul className={'flex gap-4 m-0 p-0'}>
                    {socials.map((social, index) => (
                        <a
                            key={index}
                            href={social.linkUrl}
                            className={
                                'bg-primary-black-70 flex h-10 items-center justify-center rounded-full w-10'
                            }
                        >
                            <img
                                src={social.imageSrc}
                                alt={social.imageAlt}
                                className={'m-auto'}
                            />
                        </a>
                    ))}
                </ul>
            </div>
        </div>
    );
}
