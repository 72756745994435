import Slider from 'react-slick';
import React, { useCallback, useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getTestimonial } from '../tools/fetcher';

export default function TestimonialSlider() {
    const [testimonials, setTestimonials] = useState([]);

    const Slide = useCallback(
        ({ testy }) =>
            testy ? (
                <div
                    className={
                        'bg-primary-white-45 mr-4 p-10 space-y-8 rounded-lg sm:mr-10'
                    }
                >
                    <p className={'font-bold text-base'}>
                        {testy?.description}
                    </p>
                    <div className={'flex items-start gap-6'}>
                        {testy?.imageSrc && (
                            <img
                                src={testy.imageSrc}
                                alt={testy?.imageAlt || testy?.name}
                                className={
                                    'h-12 mt-1 object-contain rounded-full w-12'
                                }
                            />
                        )}
                        <div>
                            <h1 className={'font-bold leading-7 text-base'}>
                                {testy?.name}
                            </h1>
                            <p
                                className={
                                    'text-primary-black-50 leading-7 text-base'
                                }
                            >
                                {testy?.agency}
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div>NOT FOUND</div>
            ),
        [],
    );
    const settings = {
        autoplay: true,
        autoplaySpeed: 10000,
        infinite: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: false,
        // adaptiveHeight: true,
        swipeToSlide: true,
        // prevArrow: '<span class="slickArrow slickArrow--prev"></span>',
        // nextArrow: '<span class="slickArrow slickArrow--next"></span>',
        responsive: [
            {
                breakpoint: 3000,
                settings: {
                    dots: true,
                    dotsClass:
                        'testimonials-dots !flex gap-2 h-2 items-center justify-center ml-6 mt-6 sm:justify-start',
                    centerMode: true,
                    centerPadding: '24px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        getTestimonial().then((result) => {
            if (!result?.code) {
                const dt = result.map((d) => ({
                    name: d?.title?.rendered,
                    agency: d?.testimonial_details?.agency,
                    description: d?.testimonial_details?.description,
                    imageSrc: d?.testimonial_details?.image_src,
                    imageAlt: d?.testimonial_details?.image_alt,
                }));
                setTestimonials(dt);
            }
        });
    }, []);

    return (
        <div className={'sm:col-span-2 overflow-hidden relative'}>
            <div
                className={
                    'w-[calc(100%+32px)] sm:w-[calc(100%+40px)] -ml-2 sm:ml-0'
                }
            >
                {testimonials.length > 0 &&
                    (testimonials.length === 1 ? (
                        <div className={'flex items-center justify-center px-6'}>
                            <Slide key={0} testy={testimonials[0]} />
                        </div>
                    ) : (
                        <Slider {...settings}>
                            {testimonials.map((testy, index) => (
                                <Slide key={index} testy={testy} />
                            ))}
                        </Slider>
                    ))}
            </div>
        </div>
    );
}
