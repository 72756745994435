import React from 'react';
import { Route, Switch } from 'wouter';
import Homepage from './pages/Homepage';
import BlogPageHorizontal from './pages/BlogPageHorizontal';

export default function Router() {
    return (
        <Switch>
            <Route path="/" component={Homepage} />
            <Route path="/blog" component={BlogPageHorizontal} />
        </Switch>
    );
}
