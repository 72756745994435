import React from 'react';

// import PropTypes from 'prop-types';

function Layout({ children, className }) {
    return <div className={className}>{children}</div>;
}

// ignore di eslint 'react/prop-types': 'off',
// Layout.propTypes = {
//     children: PropTypes.node.isRequired,
// };

export default Layout;
