/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
    ArrowLongLeftIcon,
    ArrowLongRightIcon,
} from '@heroicons/react/20/solid';
import { classNames } from '../tools/helpers';
import { getAuthor, getFeatureMedia, getPost } from '../tools/fetcher';

const POSTS_PER_PAGE = 6;

function Pagination({
    currentPage,
    setCurrentPage,
    totalPosts,
    postsPerPage,
    handleNextPage,
    handlePreviousPage,
}) {
    const totalPages = Math.ceil(totalPosts / postsPerPage);

    return (
        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
                <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="bg-none m-0 p-0 bg-transparent inline-flex items-center border-0 border-t-2 focus:ring-0 focus:outline-0 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:opacity-50"
                >
                    <ArrowLongLeftIcon
                        aria-hidden="true"
                        className="mr-3 h-5 w-5 text-gray-400"
                    />
                    Previous
                </button>
            </div>
            <div className="hidden md:-mt-px md:flex">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => setCurrentPage(index + 1)}
                        className={`bg-none m-0 p-0 bg-transparent inline-flex items-center border-0 border-t-2 focus:ring-0 focus:outline-0 ${
                            currentPage === index + 1
                                ? 'border-indigo-500 text-indigo-600'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                        } px-4 pt-4 text-sm font-medium`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="bg-none m-0 p-0 bg-transparent inline-flex items-center border-0 border-t-2 focus:ring-0 focus:outline-0 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:opacity-50"
                >
                    Next
                    <ArrowLongRightIcon
                        aria-hidden="true"
                        className="ml-3 h-5 w-5 text-gray-400"
                    />
                </button>
            </div>
        </nav>
    );
}

export default function BlogHorizontal({ className, data }) {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPosts, setTotalPosts] = useState(0);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await getPost(
                    currentPage,
                    POSTS_PER_PAGE,
                    false,
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const tot = parseInt(response?.headers?.get('X-WP-Total'), 10);
                setTotalPosts(tot);

                const blogs = await response.json();

                const addFeatureMedia = await Promise.all(
                    blogs.map(async (post) => {
                        const media = post.featured_media
                            ? await getFeatureMedia(post.featured_media)
                            : undefined;
                        const author = post.author
                            ? await getAuthor(post.author)
                            : undefined;
                        return {
                            ...post,
                            media,
                            author,
                        };
                    }),
                );
                setPosts(addFeatureMedia); // Simpan data ke dalam state
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchPosts().then(() => true);
    }, [currentPage]); // Tambahkan currentPage ke array dependency agar useEffect berjalan saat currentPage berubah

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    return (
        <div
            className={classNames(
                'max-w-[1160px] mx-auto font-nunito space-y-10',
                className,
            )}
        >
            {data?.blog_list && (
                <div className="mx-auto max-w-2xl text-center">
                    {data.blog_list.title && (
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            {data.blog_list.title}
                        </h2>
                    )}
                    {data.blog_list.description && (
                        <p className="mt-2 text-lg leading-8 text-gray-600">
                            {data.blog_list.description}
                        </p>
                    )}
                </div>
            )}
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {posts.map((post) => (
                    <article
                        key={post.id}
                        className="flex flex-col items-start justify-between"
                    >
                        <div className="relative w-full">
                            <img
                                alt=""
                                src={
                                    post.media
                                        ? post.media.media_details.sizes.thumbnail.source_url
                                        : 'https://via.placeholder.com/360x203'
                                }
                                className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                            />
                            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="max-w-xl">
                            <div className="mt-8 flex items-center gap-x-4 text-xs">
                                <time
                                    dateTime={post.date_gmt}
                                    className="text-gray-500"
                                >
                                    {new Date(post.date_gmt).toLocaleDateString(
                                        'en-US',
                                        {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                        },
                                    )}
                                </time>
                                <a
                                    href={post.link}
                                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                                >
                                    {post.categories.length > 0 &&
                                        post.categories[0].name}
                                </a>
                            </div>
                            <div className="group relative">
                                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                    <a href={post.link}>
                                        <span className="absolute inset-0" />
                                        {post.title.rendered}
                                    </a>
                                </h3>
                                {/* eslint-disable-next-line */}
                                <div
                                    className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600"
                                    dangerouslySetInnerHTML={{
                                        __html: post.excerpt.rendered,
                                    }}
                                />
                            </div>
                            <div className="relative mt-8 flex items-center gap-x-4">
                                <img
                                    alt={post?.author?.name}
                                    src={post?.author?.avatar_urls['48']}
                                    className="h-10 w-10 rounded-full bg-gray-100"
                                />
                                <div className="text-sm leading-6">
                                    <p className="font-semibold text-gray-900">
                                        <a href={post?.author?.link}>
                                            <span className="absolute inset-0" />
                                            {post?.author?.name}
                                        </a>
                                    </p>
                                    <p className="text-gray-600">
                                        {post?.author?.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>
                ))}
            </div>
            <Pagination
                currentPage={currentPage}
                totalPosts={totalPosts}
                postsPerPage={POSTS_PER_PAGE}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
                setCurrentPage={setCurrentPage}
            />
        </div>
    );
}
