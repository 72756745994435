import React from 'react';
import { classNames } from '../tools/helpers';

function Button({ children, className, type = 'button', ...props }) {
    const newClassName = classNames(
        'relative focus:outline-none focus:ring-0 focus:ring-offset-0',
        'text-white rounded-lg px-6 min-h-12 text-base font-bold',
        className,
    );

    if (type === 'a') {
        return (
            <a href={props.href} className={'!no-underline'} {...props}>
                <div
                    className={classNames(
                        'flex items-center justify-center',
                        newClassName,
                    )}
                >
                    {children}
                </div>
            </a>
        );
    }

    return (
        <button type={type} className={newClassName} {...props}>
            {children}
        </button>
    );
}

export default Button;
